<template>
    <div class="tj-details-main" v-if="tjDetails">
        <div class="detail-head"  :style='"background-image:url("+require("@/assets/img/detail-head-back-"+(tjDetails.userGender==$t("pe.female")?"woman":tjDetails.userGender==$t("pe.male")?"man":"")+".png")+")"'>
            <p class="sex">{{tjDetails.userGender}}</p>
            <p class="name">{{$t("pe.userName",{userName:tjDetails.userName})}}</p>
            <div class="times">
                <p class="txt">{{$t("pe.detectionTime")}}：</p>
                <p class="txt">{{tjDetails.validDate}}</p>
            </div>
        </div>
        <div class="detail-tabs">
            <div class="tabs-head">
                <i class="bottom-line" :style="ulineLateX"></i>
                <i class="top-triangle" :style="ulineLateX"></i>
                <p class="tabs-item" @click="tabActive=1" :class="{'active':tabActive == 1}">{{$t('pe.detailedExamination')}}</p>
                <p class="tabs-item" @click="tabActive=2" :class="{'active':tabActive == 2}">{{$t("pe.pooledAnalysis")}}</p>
                <p class="tabs-item" @click="tabActive=3" :class="{'active':tabActive == 3}">{{$t("pe.clinicAnalysis")}}</p>
            </div>
            <div class="check-item tab-content" v-if="tabActive == 1" >
                <div class="item-collapres" v-for="(item,index) in tjDetails.peModules" :key="index">
                    <div class="colllapres-title">
                        <div class="title-box" :class="{'collapse-open':item.collapseType}">
                            <div class="text">
                                <p>
                                    <span class="name" :style="{'--maxwidth':item.normalNum>0?'6rem':'10.5rem'}">{{item.moduleName}}</span>
                                    <i :class="'singular'+item.resultLevel"></i>
                                </p>
                                <p v-if="item.normalNum>0" class="singularNum">
                                    <span>{{item.normalNum}}</span>{{$t("pe.focusOn")}}
                                </p>
                            </div>
                            <i class="collapse-right-icon" :class="{'collapse-right-top':item.collapseType}" @click="item.collapseType = !item.collapseType"></i>
                        </div>
                            <!-- normalNum -->
                    </div>
                    <div class="collapse-child" v-if="item.collapseType">
                        <template v-for="(Imgitem,cindex) in item.imgUrls">
                            <div class="child-images" :key="cindex" @click="Imgview(item.imgUrls)">
                                <p class="title"></p>
                                <div class="images-box">
                                    <img :src="Imgitem">
                                </div>
                            </div>
                            <div class="emtry" :key="cindex+'ll'"></div>
                        </template>
                        <div class="child-commonly" v-if='item.moduleType == $t("pe.ordinary")'>
                            <table class="table-box">
                                <thead>
                                    <tr>
                                        <td>{{$t("pe.checkProject")}}</td>
                                        <td>{{$t("pe.checkResult")}}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(citem,cindex) in item.peItems" :key="cindex">
                                        <td valign="top" :class="{'text-red':citem.resultFlag!=''}">{{citem.itemName}}</td>
                                        <td :class="{'text-red':citem.resultFlag!=''}">{{citem.result}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="dotted-box">
                                <i class="radius"></i>
                                <i class="dotted-line"></i>
                                <i class="radius"></i>
                            </div>
                            <div class="conclusion">
                                <div class="conclusion-item">
                                    <p class="title">{{$t("pe.medicalConclusion")}}：</p>
                                    <p class="desc-txt">{{item.result}}</p>
                                </div>
                                <div class="conclusion-item">
                                    <p class="title">{{$t("pe.checkDoctor")}}：</p>
                                    <p class="desc-txt">{{item.validDoctor||item.doctor}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="child-checkouts" v-if='item.moduleType == $t("pe.InspectionClass")'>
                            <table class="table-box">
                                <thead>
                                    <tr>
                                        <td>{{$t("pe.checkProject")}}</td>
                                        <td>{{$t("pe.conclusion")}}</td>
                                        <td>{{$t("pe.unit")}}</td>
                                        <td>{{$t("pe.referenceRange")}}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(citem,cindex) in item.peItems" :key="cindex">
                                        <td valign="top" :class="{'text-red':citem.resultFlag!=''}">{{citem.itemName}}</td>
                                        <td :class="{'text-red':citem.resultFlag!=''}">{{citem.result}} <i v-if="citem.resultFlag" :class="citem.resultFlag.indexOf('↑')>-1?'arrow-top':'arrow-bottom'"></i> </td>
                                        <td :class="{'text-red':citem.resultFlag!=''}">{{citem.unit}}</td>
                                        <td align="center" :class="{'text-red':citem.resultFlag!=''}">{{citem.reference}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="dotted-box">
                                <i class="radius"></i>
                                <i class="dotted-line"></i>
                                <i class="radius"></i>
                            </div>
                            <div class="conclusion">
                                <div class="conclusion-item">
                                    <p class="title">{{$t("pe.AcquisitionTime")}}：</p>
                                    <p class="desc-txt">{{item.bloodDate}}</p>
                                </div>
                                <div class="conclusion-item">
                                    <p class="title">{{$t("pe.detectionInstitutions")}}：</p>
                                    <p class="desc-txt">{{item.checkOrg}}</p>
                                </div>
                                <div class="conclusion-item">
                                    <p class="title">{{$t("pe.specimenStatus")}}：</p>
                                    <p class="desc-txt">{{$t("pe.statusText")}}</p>
                                </div>
                                <div class="conclusion-item">
                                    <p class="title">{{$t("pe.checkout")}}：</p>
                                    <p class="desc-txt">{{item.checkDoctor}}</p>
                                </div>
                                <div class="conclusion-item">
                                    <p class="title">{{$t("audit")}}：</p>
                                    <p class="desc-txt">{{item.validDoctor}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="remarks" v-if="tjDetails">
                    {{$t("pe.wraningTxt")}}
                </p>
            </div>
            <div class="Summary-item  tab-content" v-if="tabActive == 2" >
                <div class="Summary-box">
                    <div class="head-box"></div>
                    <p class="text" v-html="tjDetails.conclusion"></p>
                    <div class="text-bottom">
                       <div class="box">
                            <p class="null">&nbsp;</p>
                            <p class="txt">{{$t("pe.trial")}}:{{tjDetails.checkDoctor}}</p>
                       </div>
                       <div class="box">
                            <p class="null">&nbsp;</p>
                        <p class="txt">{{$t("pe.final")}}:{{tjDetails.validDoctor}}</p>
                       </div>
                       <div class="box">
                            <p class="null">&nbsp;</p>
                            <p class="txt">{{tjDetails.validDate}}</p>
                       </div>
                    </div>
                    <div class="bottom-box">

                    </div>
                </div>
                <p class="remarks" v-if="tjDetails">
                    {{$t("pe.wraningTxt")}}
                </p>
            </div>
            <div class="seeDockor-item tab-content" v-if="tabActive == 3" >
                <!-- <div class="seeDockor-head">
                    <div class="head-item itemA">
                        <div class="head-title">
                            A
                        </div>
                        <div class="head-txt">
                            无异常
                        </div>
                    </div>
                    <div class="head-item itemB">
                        <div class="head-title">
                            B
                        </div>
                        <div class="head-txt">
                            不需要关 注体征
                        </div>
                    </div>
                    <div class="head-item itemC">
                        <div class="head-title">
                            C
                        </div>
                        <div class="head-txt">
                            需定期复 查的体质
                        </div>
                    </div>
                    <div class="head-item itemD">
                        <div class="head-title">
                            D
                        </div>
                        <div class="head-txt">
                            需进一步 检查或诊治
                        </div>
                    </div>
                    <div class="head-item itemE">
                        <div class="head-title">
                            E
                        </div>
                        <div class="head-txt">
                            重大阳性 体征
                        </div>
                    </div>
                </div> -->
                <div class="seeDocker-table">
                    <table class="table-box">
                        <thead>
                            <tr>
                                <td style="width: 4rem;">{{$t("pe.checkProject")}}</td>
                                <td>{{$t("pe.diagnosis")}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(citem,cindex) in tjDetails.peModules" :key="cindex">
                                <td valign="top" align="left">{{citem.moduleName}}</td>
                                <td>{{citem.result}}</td>
                                <!-- <td align="center">-</td> -->
                                <!-- <td align="center" :class="'text'+citem.resultLevel">{{citem.resultLevel}}</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="remarks" v-if="tjDetails">
                    {{$t("pe.wraningTxt")}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { getDetails, medicalPeGet } from "@/api/medicalReport";
import { ImagePreview } from 'vant';

export default {
    data() {
        return {
            // 体检详情
            tjDetails:{},
            peCode:'',
            tabActive:1,
            line:{
                transform: 'translateX(0%)',
            }
        }
    },
    computed:{
        ulineLateX(){
            this.line = {
                transform:'translateX('+(document.body.clientWidth/3*(this.tabActive-1))+'px)'
            }
            return this.line;
        }
    },
    created(){
        document.title = this.$t("pe.medicaldetails");
        this.peCode = this.$route.query.peCode;
        this.getDetailData();
    },
    methods:{
        async getDetailData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let datadd = {
                peCode:this.peCode,
                userId:this.$route.query.userId,
                tenantId:this.$route.query.tenantId,
                source:this.$route.query.source
            }
            let result = await getDetails(datadd);
            toast.clear();
            if(result.data.errorCode == 0){

                if(result.data.data.reportUrl){
                this.$router.replace({
                    path:"/pdfView",
                    query:{
                        pdfUrl:result.data.data.reportUrl
                    }
                })
                return
            }


                for (let i = 0; i < result.data.data.peModules.length; i++) {
                    let num = 0;
                    if(result.data.data.peModules[i].moduleType == this.$t("pe.InspectionClass")){
                        for (let j = 0; j < result.data.data.peModules[i].peItems.length; j++) {
                            if(result.data.data.peModules[i].peItems[j].resultFlag!=''){
                                num ++;
                                result.data.data.peModules[i].peItems[j].abnormalType = true;
                            }else{
                                result.data.data.peModules[i].peItems[j].abnormalType = false;
                            }
                        }
                    }
                    result.data.data.peModules[i].normalNum = num;
                    result.data.data.peModules[i].collapseType = false;
                }
                this.tjDetails = result.data.data;
            }else{
                this.$toast(this.$t("pe.getDetailsFail"));
            }
        },
        Imgview(imgs){
            ImagePreview(imgs)
        }
    }
}
</script>

<style lang="scss">
.tj-details-main{
    height: 100%;
    width: 100%;
    background: #ECEEF4;
    overflow: hidden;
    .detail-head{
        height: 8.4rem;
        // background-image: u;
        background-size: 100% 100%;
        position: relative;
        .sex{
            position: absolute;
            width: 1.3rem;
            height: 1.3rem;
            font-size: .64rem;
            font-family: PingFang SC;
            font-weight: 600;
            color: #FFEC47;
            display: flex;
            justify-content: center;
            align-items: center;
            right: .54rem;
            bottom: 2.2rem;
        }
        .name{
            position: absolute;
            width: max-content;
            height: 1.72rem;
            max-width: 11.15rem;
            line-height: 1.72rem;
            text-align: center;
            font-size: .68rem;
            font-family: PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            max-width: 6rem;
            right: 8.68rem;
            top: 2.16rem;
            padding: 0 0.6rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: url(/assets/img/detail-head-name-back.f4b80234.png) no-repeat;
            background-size: 100% 100%;
        }
        .times{
            position: absolute;
            font-size: .52rem;
            font-family: PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: .72rem;
            width: 4.06rem;
            box-sizing: border-box;
            padding-left: .6rem;
            height: 1.88rem;
            display: flex;
            justify-content: center;
            flex-direction: column;
            bottom: 1.78rem;
            left: 2.52rem;

        }
    }
    .detail-tabs{
        height: calc(100% - 8.4rem);
        .remarks{
            padding: .8rem .3rem;
            text-align: center;
            font-size: .44rem;
            font-family: PingFang SC;
            font-weight: 400;
            background: #FFFFFF;
            color: #C1C4CB;
        }
        .tabs-head{
            display: flex;
            background: #FFFFFF;
            position: relative;
            .tabs-item{
                flex: 1;
                height: 2rem;
                line-height: 2rem;
                text-align: center;
                cursor: pointer;
                font-size: .64rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #606266;
            }
            .active{
                font-weight: 600;
                color: #49A7FF;
            }
            .bottom-line{
                width: 1.6rem;
                height: .16rem;
                background: #49A7FF;
                border-radius: .08rem;
                position: absolute;
                bottom: 0;
                left: calc((100%/6) - .8rem);
                transition:all 500ms ease;
            }
            .top-triangle{
                width: 0;
                height: 0;
                position: absolute;
                border-left: .47rem solid transparent;
                border-right: .47rem solid transparent;
                border-top: .48rem solid #3988FF;
                top: -0.05rem;
                transition:all 500ms ease;
                left: calc((100%/6) - .48rem);
            }
        }
        .tab-content{
            height: calc(100% - 2rem);
            overflow-y: scroll;
        }
        .check-item{
            .item-collapres{
                .colllapres-title{
                    height: 2.08rem;
                    background: #FFFFFF;
                    padding: 0 .6rem;
                    .title-box{
                        align-items: center;
                        display: flex;
                        height: 100%;
                        width: 100%;
                        // border-bottom: .02rem solid #E5E7EC;
                        // box-sizing: border-box;
                        .text{
                            font-size: .64rem;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #303133;
                            flex: 1;
                            display: flex;
                            p{
                                display: flex;
                                align-items: center;
                                flex: 1;
                                .name{
                                    display: block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    max-width: var(--maxwidth);
                                }
                            }
                            .singularNum{
                                font-size: .48rem;
                                font-family: PingFang SC;
                                font-weight: 400;
                                color: #919398;
                                flex: initial;
                                span{
                                    color: #FF671C;
                                }
                            }
                            .singularA,.singularB,.singularC,.singularD,.singularE{
                                width: 2.8rem;
                                display: block;
                                height: .6rem;
                                margin-left: .2rem;
                            }
                            .singularA{
                                background: url("~@/assets/img/singularA.png") no-repeat;
                                background-size: 100% 100%;
                            }
                            .singularB{
                                background: url("~@/assets/img/singularB.png") no-repeat;
                                background-size: 100% 100%;
                            }
                            .singularC{
                                background: url("~@/assets/img/singularC.png") no-repeat;
                                background-size: 100% 100%;
                            }
                            .singularD{
                                background: url("~@/assets/img/singularD.png") no-repeat;
                                background-size: 100% 100%;
                            }
                            .singularE{
                                background: url("~@/assets/img/singularE.png") no-repeat;
                                background-size: 100% 100%;
                            }
                        }
                        .collapse-right-icon{
                            display: block;
                            height: .25rem;
                            width: .5rem;
                            margin-left: .2rem;
                            background: url("~@/assets/img/tj_duibi_top_icon.png") no-repeat center;
                            background-size: .5rem .25rem;
                            transition:all 300ms ease;
                            padding: .25rem;
                        }
                        .collapse-right-top{
                            transform: rotate(180deg);
                        }
                    }
                }
                .collapse-open{
                    border: 0 !important;
                    .text{
                        font-weight: 600 !important;
                    }
                    .collapse-right-icon{
                        transform:rotate(180deg);
                        -ms-transform:rotate(180deg); 	/* IE 9 */
                        -moz-transform:rotate(180deg); 	/* Firefox */
                        -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
                        -o-transform:rotate(180deg); 	/* Opera */
                    }
                }
                .collapse-child{
                    margin: .4rem .6rem;
                    background: #FFFFFF;
                    .emtry{
                        height: 0.6rem;
                        background: #ECEEF4;
                    }
                    .child-images{
                        // margin-bottom: 0.6em;
                        // background: #FFFFFF;
                        border-radius: 0.08rem;
                        .title{
                            height: 1.6rem;
                            position: relative;
                            background: #BBD7FB;
                            &::after{
                                content: ' ';
                                width: .6rem;
                                height: .6rem;
                                display: block;
                                background: #ECEEF4;
                                border-radius: 50%;
                                position: absolute;
                                bottom: -0.3rem;
                                left: -0.3rem ;
                            }
                            &::before{
                                content: ' ';
                                width: .6rem;
                                height: .6rem;
                                display: block;
                                background: #ECEEF4;
                                border-radius: 50%;
                                position: absolute;
                                bottom: -0.3rem;
                                right: -0.3rem ;
                            }
                        }
                        .images-box{
                            padding: 0.6rem;
                            img{
                                width: 100%;
                            }
                        }
                    }
                    .dotted-box{
                        display: flex;
                        height: .6rem;
                        width: 100%;
                        align-items: center;

                        .radius{
                            width: .6rem;
                            height: .6rem;
                            display: block;
                            background: #ECEEF4;
                            border-radius: 50%;
                            &:last-child{
                                margin-right: -.3rem;
                            }
                            &:first-child{
                                margin-left: -.3rem;
                            }
                        }
                        .dotted-line{
                            flex: 1;
                            margin: .4rem;
                            border-top: .04rem dashed #C1C4CB;
                        }
                    }
                    .conclusion{
                        width: 100%;
                        padding: .6rem .7rem;
                        box-sizing: border-box;
                        .conclusion-item{
                            display: flex;
                            font-size: .56rem;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #303133;
                            line-height: .8rem;
                            margin-bottom: .3rem;
                            .title{
                                font-weight: 600;
                                padding-left: .3rem;
                                flex: auto 0 0;
                                position: relative;
                                width: max-content;
                                height: .8rem;
                                &::after{
                                    position: absolute;
                                    height: 75%;
                                    top: 15%;
                                    left: 0;
                                    width: .11rem;
                                    display: block;
                                    content: ' ';
                                    background: #3988FF;
                                    border-radius: .05rem;
                                }
                            }
                        }
                    }
                    .child-checkouts{
                        thead{
                            tr {
                                td{
                                    &:nth-child(4){
                                        padding: 0;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                        tbody{
                            tr{
                                td{
                                    font-size: .48rem;
                                }
                            }
                        }
                        .arrow-top,.arrow-bottom{
                            width: .28rem;
                            height: .4rem;
                            display: inline-block;
                            margin-left: .2rem;
                        }
                        .arrow-top{
                            background: url("~@/assets/img/arrow_top_ion.png") no-repeat;
                            background-size: 100% 100%;
                        }
                        .arrow-bottom{
                            background: url("~@/assets/img/arrow_bottom_ion.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
            }
        }
        .Summary-item{
            // height: 100%;
            background-color: #FFFFFF;
            padding: 0 .6rem;
            padding-top: .6rem;
            box-sizing: border-box;
            .head-box{
                height:1.26rem;
                width: 100%;
                background: url("~@/assets/img/Summary-item-head.png") no-repeat;
                background-size: 100% 100%;
            }
            .bottom-box{
                height: 1.28rem;
                width: 100%;
                background:url("~@/assets/img/Summary-item-head-bottom.png") no-repeat;
                background-size: 102% 100%;

            }
            .text{
                padding: .4rem .6rem;
                background-color: #F1F8FF;
                font-size: .56rem;
                font-family: PingFang SC;
                // font-weight: 600;
                color: #606266;
                line-height: .88rem;
            }
            .text-bottom{
                width: 100%;
                padding: 0 .6rem;
                box-sizing: border-box;
                background-color: #F1F8FF;
                .box{
                    display: flex;
                    .null{
                        flex: 1;
                    }
                    .txt{
                        font-size: .56rem;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #606266;
                        margin-right: .5rem;
                        line-height: .88rem;
                        width: 4.5rem;
                    }
                }
            }
        }
        .seeDockor-item{
            .remarks{
                background: none;
            }
            .seeDockor-head{
                padding: .6rem .6rem .4rem .6rem;
                width: 100%;
                box-sizing: border-box;
                background: #FFFFFF;
                display: flex;
                .head-item{
                    flex: 1;
                    border: .02rem solid #E5E7EC;
                    border-left: 0;
                    &:first-child{
                        border-left: .02rem solid #E5E7EC;
                    }
                    .head-title{
                        height: 1.2rem;
                        background: #E0EDFF;
                        color: #49A7FF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .head-txt{
                        padding: .3rem;
                        font-size: .48rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #606266;
                        line-height: .68rem;
                        text-align: center;
                    }
                }
                .itemA{
                    .head-title{
                        background: #E0EDFF;
                        color: #49A7FF;
                    }

                    border-top: .16rem solid #49A7FF;
                }
                .itemB{
                    .head-title{
                        background: #E0FFF5;
                        color: #5BEC3E;
                    }
                    border-top: .16rem solid #5BEC3E;
                }
                .itemC{
                    .head-title{
                        background: #FCFFE0;
                        color:#FFD21C;
                    }
                    border-top: .16rem solid #FFD21C;
                }
                .itemD{
                    .head-title{
                        background: #FFEDE0;
                        color: #FF671C;
                    }
                    border-top: .16rem solid #FF671C;
                }
                .itemE{
                    .head-title{
                        background: #FFE0E0;
                        color: #FF1C1C;
                    }
                    border-top: .16rem solid #FF1C1C;
                }
            }
            .seeDocker-table{
                margin: .4rem .6rem;
                background: #FFFFFF;
                .textA{
                    color: #49A7FF;
                }
                .textB{
                    color: #5BEC3E;
                }
                .textC{
                    color: #FFD21C;
                }
                .textD{
                    color: #FF671C;
                }
                .textE{
                    color: #FF1C1C;
                }
            }
        }
    }
    .table-box{
        width: 100%;
        thead{
            tr{
                border-radius: .08rem .08rem 0 0;
                td{
                    height: 1.6rem;
                    padding:0 .2rem;
                    background: #BBD7FB;
                    font-size: .56rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #319BFF;
                    &:first-child{
                        padding-left: .6rem;
                    }
                }
            }
        }
        tbody{
            td{
                padding:.3rem  0;
                padding-left: .2rem;
                font-size: .56rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #606266;
                &:first-child{
                    padding-left: .6rem;
                }
            }
        }
        .text-red{
            color: #FF671C;
        }
    }
}

</style>
